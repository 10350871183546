@import "../others/variables";

.signin-container {
  background-color: $bg-color; 
}

.signin-row {
  display: flex;
}

.signin-form-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.signin-form-wrapper {
  width: 100%;
  padding: 2rem;
}

.signin-loader {
  display: block;
  margin: 0 auto;
}

.signin-form-group {
  padding-top: 1rem;
}

.signin-input {
  border: 1px solid #ddd;
  padding: 0.5rem 1rem;
  border-radius: 5px;
}

.signin-error {
  color: #dc3545;
}



.signin-image-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.signin-image {
  width: 70%;
  height: 85vh;
  object-fit: cover;
  border-radius: 0 5px 5px 0;
}
