@import "others/main-layout";
@import "others/variables";
@import "./others/calendar";
@import "./others/circleMap";
@import "./others/circleStatus";
@import "./others/signin";
@import "./others/fonts";



.bgColor {
  background-color: $bg-color;
}

.bg-grey {
  background-color: $olive-green;
  height: 100%;
}

.bg-darkblue {
  background-color: $darkblue;
  height: 100%;
}

.btn-custom {
  background-color: $orange;
  color: white;
  border: none;
  border-radius: 0.375rem;
  padding: 0.375rem 0.75rem;
}

.btn-custom-gradient {
  background-color: $orange;
  color: white;
  border: none;
  border-radius: 0.375rem;
  padding: 0.375rem 0.75rem;
  background: linear-gradient(to right, $orange, #a6613f);
}

.btn-custom-green {
  background-color: $olive-green;
  color: white;
  border: none;
  border-radius: 0.375rem;
  padding: 0.375rem 0.75rem;
}

.btn-custom:hover {
  background-color: $orange;
}

.top-padding-20 {
  padding-top: 20px;
}

.font-orange {
  color: $orange;
}

.font-green {
  color: $olive-green;
}

.currency-symbol::before {
  content: "\20B9"; /* Unicode for ₹ symbol */
  margin-right: 0.2em;
}



.badge-class {
  background-color: $orange;
}

.select-class {
  border-color: $olive-green;
}

.image-container {
  position: relative;
  text-align: center;
}

.image-container img {
  max-height: 25vh;
  border-radius: 5px;
}

.overlay-text {
  position: absolute;
  top: 58%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 15px;
  font-weight: bold;
  text-shadow: 1px 1px 2px grey;
  z-index: 1;
  white-space: pre-wrap;
}

.overlay-text-all {
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 15px;
  font-weight: bold;
  text-shadow: 1px 1px 2px grey;
  z-index: 1;
  white-space: pre-wrap;
}

.overlay-text-normal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 15px;
  text-shadow: 1px 1px 2px grey;
  z-index: 1;
  white-space: pre-wrap;
}

.dot-green {
  font-size: 50;
  color: green;
}

.centered-chart {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.nav-link.active {
  background-color: #161B87;
  border-left: 3px solid #ffffff;
  font-weight: 500;
  // border-radius: 5px;
}

.nav-link.active-lite {
  background-color: #161B87;
  border-left: 3px solid #ffffff;
  // border-radius: 5px;
}

.criticalDot {
  background-color: #ffa31a;
  border-radius: 50%;
  width: 10px;
  height: 10px;
  display: inline-block;
  margin-left: 20px;
}

.runningDot {
  background-color: #3289a8;
  border-radius: 50%;
  width: 10px;
  height: 10px;
  display: inline-block;
}

.fixed-right {
  width: 318px;
  height: 100vh;
  color: white;
  border-radius: 5px;
  position: fixed;
  right: 0;
  top: 60;
  // padding: 1rem;
  overflow-y: hidden;
  background-color: $olive-green;
}

.fixed-right-lite {
  width: 318px;
  height: 100vh;
  color: white;
  border-radius: 5px;
  position: fixed;
  right: 0;
  top: 60;
  // padding: 1rem;
  overflow-y: hidden;
  background: linear-gradient(to right, #171C97, #4455ae); 
  // background-color: $darkblue;
}


.headerLogo{
  height: 56px;
  max-width: 250px;
  background: linear-gradient(to right, #1E29A4, #3A5CDA);
}