@font-face {
  font-family: "MonaSans";
  src: url("../../fonts/mona-sans/Mona-Sans.ttf") format("truetype");
}

main,aside,header {
  font-family: "MonaSans", sans-serif;
}

.font-robot-21 {
  font-size: 21px;
}

.font-robot-17 {
  font-size: 17px;
}

.font-robot-16 {
  font-size: 16px;
}

.font-robot-13 {
  font-size: 13px;
}

.font-robot-14 {
  font-size: 14px;
}
