.mainDiv {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 45vh;
  // margin: 0;
}

.circle {
  position: absolute;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: black;
  // font-size: 1.2em;
  // font-weight: bold;
}
.circle1 {
  width: 120px;
  height: 120px;
  background-color: #f4c983;
  top: 140px;
  left: 10px;
  z-index: 1;
}
.circle2 {
  width: 80px;
  height: 80px;
  background-color: #f4f4f4;
  top: 120px;
  left: 210px;
  z-index: 1;
}
.circle3 {
  width: 150px;
  height: 150px;
  background-color: #ffa31a;
  top: 140px;
  left: 100px;
}
.circle4 {
  width: 100px;
  height: 100px;
  background-color: #c3c8c8;
  top: 250px;
  left: 160px;
}
