@import "../assets/scss/others/variables";

.layout {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.header {
  position: fixed;
  width: calc(100% - 265px); /* Adjusted to match the new sidebar width */
  top: 0;
  left: 265px; /* Adjusted to match the new sidebar width */
  z-index: 2;
}

.sidebar, .lite-sidebar {
  position: fixed;
  top: 0;
  left: 0;
  width: 265px; /* Increased sidebar width */
  height: 100vh;
  background: linear-gradient(to right, #161B96, #2850d5);
  color: white;
  z-index: 1;
}

.main {
  display: flex;
  margin-top: 4rem;
  height: calc(100vh - 4rem);
}

.content {
  flex: 1;
  margin-left: 267px; /* Adjusted to match the new sidebar width */
  overflow-y: auto;
  overflow-x: hidden;
  z-index: 1;
}

.content::-webkit-scrollbar {
  display: none;
}

@media (max-width: 768px) {
  .header {
    width: 100%;
    left: 0;
  }

  .sidebar {
    width: 250px; /* Adjusted width for smaller screens */
    position: absolute;
    height: calc(100vh - 4rem);
    z-index: 2;
    transition: transform 0.3s ease;
    transform: translateX(-100%);
  }

  .sidebar.open {
    transform: translateX(0);
  }

  .main {
    margin-top: 4rem; /* Adjust according to header height */
    height: calc(100vh - 4rem); /* Adjust according to header height */
  }

  .content {
    margin-left: 0;
  }
}

@media (max-width: 480px) {
  .header {
    font-size: 0.9rem;
    // padding: 0.5rem;
  }

  .sidebar {
    width: 200px; /* Adjusted width for even smaller screens */
  }
}
