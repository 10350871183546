@import "./variables";

.calendar-container {
  display: flex;
  flex-direction: column;
  height: 55vh;
  max-width: 100%;
  background-color: #f8f9fa;
}

.calendar-wrapper {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
}

.react-calendar {
  width: 100%;
  height: auto;
  max-width: 600px;
  background: white;
  border: 1px solid #a0a096;
  // font-family: Arial, Helvetica, sans-serif;
  line-height: 1.125em;
  background: $bg-color !important;
}

.react-calendar__tile--highlight {
  background: #ffa31a !important;
  color: white !important;
}

.event-details {
  flex: 1;
  padding: 20px;
  overflow-y: auto;
}

.events {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.events li {
  font-size: 0.75em;
  line-height: 1.2em;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.popup-inner {
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

.close-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  cursor: pointer;
}

.close {
  border: none;
  background: none;
  font-size: 1.5rem;
  cursor: pointer;
}

//Add Calender Style
.modal-content {
  background-color: white;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #e9ecef;
  padding-bottom: 10px;
}

.modal-title {
  margin: 0;
}

.modal-body {
  padding-top: 10px;
}

.form-group {
  margin-bottom: 15px;
}

.square-file-input {
  position: relative;
  width: 100%;
  // height: 100px;
  border: 1px solid #ced4da;
  border-radius: 4px;
  padding: 10px;
  text-align: center;
  line-height: 80px;
  // background-color: $bg-color;
}

.square-file-input input[type='file'] {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0;
  cursor: pointer;
}
