/* StatusCircles.css */
.status-circles-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    border-radius: 10px;
  }
  
  .circle {
    // position: relative;
    display: flex;
    justify-content: right;
    align-items: center;
    border-radius: 50%;
    margin: 10px 0;
    color: black;
  }
  
  .circle-label {
    position: relative;
    // color: white;
    font-size: 15px;
  }
  
  .circle.running {
    width: 240px;
    height: 240px;
    background-color: #e0e0e0;
  }
  
  .circle.not-running {
    width: 200px;
    height: 200px;
    background-color: #ffcc5c;
  }
  
  .circle.idle {
    width: 150px;
    height: 150px;
    background-color: #808080;
  }
  
  .circle.critical {
    width: 100px;
    height: 100px;
    background-color: #ff9933;
  }
  
  .status-labels {
    display: flex;
    justify-content: center;
    // width: 100%;
    margin-top: 120%;
  }
  
  .label {
    padding: 1px;
    border-radius: 2px;
    color: white;
    font-size: 15px;
    width: auto;
  }
  
  .label.running {
    background-color: #e0e0e0;
    color: #000;
  }
  
  .label.not-running {
    background-color: #ffcc5c;
  }
  
  .label.idle {
    background-color: #808080;
  }
  
  .label.critical {
    background-color: #ff9933;
  }
  