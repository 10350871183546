/* CustomCalendar.css */

.rs-calendar {
    background-color: #ffffff; 
  }
  
  .rs-calendar .rs-calendar-header {
    background-color: #ffffff; 
  }
  
  .rs-calendar .rs-calendar-body {
    background-color: #ffffff; 
  }
  
  .rs-calendar .rs-calendar-day {
    background-color: #ffffff;
  }
  