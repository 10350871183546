.loading-container {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    padding-left: 30%;
    padding-top: 1%;
    z-index: 1;
  }
  
  .loading-content {
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    background-color: #FFA31A;
    padding: 4px;
    border-radius: 5px; 
    z-index: 1;
  }